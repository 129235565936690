import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { FileApi } from '../../api/file.api';
import { HttpEventType } from '@angular/common/http';
import { ErrorHandlerService } from '../../services';
import { IFile } from '../../interfaces';

@Component({
  standalone: true,
  selector: 'dt-file-upload-component',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  imports: [SharedModule],
})
export class FileUploadComponent {
  @ViewChild('fileInput') public fileInput?: ElementRef;

  @Input() public uploadedFiles: IFile[] = [];
  @Output() public addedFile: EventEmitter<IFile> = new EventEmitter<IFile>();
  @Output() public removedFile: EventEmitter<IFile> = new EventEmitter<IFile>();

  public isDragover: boolean = false;

  constructor(private _fileApi: FileApi, private _errorHandlerService: ErrorHandlerService) {}

  public onChangeFiles(event: any): void {
    this._uploadFiles(event.target.files);
  }

  public handleDragOver(): void {
    if (!this.isDragover) {
      this.isDragover = true;
    }
  }

  public handleDragLeave(): void {
    this.isDragover = false;
  }

  private _uploadFiles(files: File[]): void {
    for (const file of files) {
      this._fileApi.uploadFile(file).subscribe({
        next: (response) => {
          switch (response.type) {
            case HttpEventType.Response:
              if (response.body) {
                this.addedFile.next({ id: response.body.data.id, name: file.name });
              }
              break;
          }
        },
        error: (err) => {
          this._errorHandlerService.reportError('FILES', 'Upload file', err);
        },
      });
    }
  }
}
