<div fxLayout="column" fxLayoutGap="12px">

  <div class="files" fxLayout="column" fxLayoutGap="12px">
    <div *ngFor="let file of uploadedFiles"
         class="file"
         fxLayout="row"
         fxLayoutGap="3px"
         fxLayoutAlign="space-between center">
      <div class="name">{{file.name}}</div>
      <button mat-icon-button
              (click)="removedFile.emit(file)"
              [matTooltip]="'COMMON.REMOVE' | translate"
              class="btn-icon-sm" color="warn">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div *ngIf="uploadedFiles.length === 0" class="dt-text dt-text-center">Lista plików jest pusta.</div>
  </div>

  <div id="dropzone" [class.dragover]="isDragover" (dragover)="handleDragOver()" (dragleave)="handleDragLeave()">
    <input type="file" id="fileDrop" multiple #fileInput (change)="onChangeFiles($event)" />
    <h3>Przeciągnij i upuść pliki tutaj</h3>
    <h3>lub</h3>
    <label for="fileDrop">Dodaj pliki ręcznie</label>
  </div>
</div>
